import React from "react"
import Layout from "../components/layout"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core"
import { StaticImage } from "gatsby-plugin-image"
import Constants from "../config/constants"
import Paper from "@material-ui/core/Paper"
import Seo from "../components/seo"

const styles = makeStyles(theme => {
  return {
    container: {
      paddingTop: theme.spacing(2)
    },
    root: {
      maxWidth: 1000,
      overflow: "hidden",
      height: 250
    },
    media: {
      height: 250
    }
  }
})

const AboutContainer = () => {
  const classes = styles()
  return (
    <Container maxWidth="lg" className={classes.container}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={3}>
          <Paper className={classes.root}>
            <StaticImage
              src='../images/Owner.png'
              alt={`${Constants.siteMetadata.defaultTitle}`}
              placeholder="tracedSVG"
              loading='eager'
              quality='100'
              className={classes.media}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={9}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="h6" component="h6" align="justifyContent"
              >
                Why choose Square Electrical?
              </Typography>
              <Typography variant="body1" component="p" align="justifyContent"
              >
                We are electrical experts who dedicated their time creating a
                unique
                electrical company. We care about our clients and it is our
                belief
                that each and every customer deserve to be treated with same
                respect
                and honesty as member of our family. Also, We deliberately
                consider
                our customers as part of Square Electrical family. We enjoy
                working
                for our customers, and our customers are happy that they hired
                us.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" component="h6" align="justifyContent"
              >
                Standard of Excellence
              </Typography>
              <Typography variant="body1" component="p" align="justifyContent">
                We established our company based upon delivering the best
                possible
                service and quality in a timely manner to our customers. In
                addition, we begin putting out more than is expected in all jobs
                in
                order to bring maximum satisfaction for our valuable customers.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" component="h6" align="justifyContent"
              >
                Safety
              </Typography>
              <Typography variant="body1" component="p" align="justifyContent">
                Customers and employees safety is our number one priority.
                Square
                Electrical strongly follows national electrical code, OSHA, and
                state regulation to brings maximum safety for its clients and
                workers. Also, we are licensed and insured.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" component="h6" align="justifyContent"
              >
                One important fact before calling an electrical company
              </Typography>
              <Typography variant="body1" component="p" align="justifyContent"
              >
                When you are looking for a company to provide electrical service
                for
                your building, it is important to call a company that mainly
                deliver
                electrical services. Some companies on the market offer variety
                of services, but they are not really experts in any of them. We,
                Square Electrical electricians are trained exclusively to
                repair,
                maintain, and install electrical equipment and wiring.
              </Typography>
              <br/>
              <Typography variant="subtitle2" color="textSecondary"
                          align="justifyContent"
              >
                Thanks,
                <br/>
                Yahya - CEO
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  )
}

export default function About() {
  return (
    <Layout header="About">
      <Seo/>
      <AboutContainer/>
    </Layout>
  )
}
